import { NavLink, useLocation } from 'react-router-dom'
// @ts-ignore
import { NavHashLink } from 'react-router-hash-link';

import './Footer.css'
import {FooterPropsType} from  './types'

import Logo from '../../assets/svg/Logo.svg'
import Facebook from '../../assets/svg/facebook.svg'
import Twitter from '../../assets/svg/twitter.svg'
import Instagram from '../../assets/svg/instagram.svg'
import LinkedIn from '../../assets/svg/linkedin.svg'

export default function Footer (props: FooterPropsType) {
  const location = useLocation()

  const pathList = [
    {
      title: 'About',
      url: '/about'
    },
    {
      title: 'FAQs',
      url: '/faqs'
    },
    {
      title: 'Contact Us',
      url: '/contact-us'
    },
    {
      title: 'Privacy',
      url: '/privacy'
    },
    {
      title: 'Terms',
      url: '/terms'
    },
  ]


  return (
    <div className='footer-container'>
      <div className='company-info-section'>
        <NavHashLink 
          exact to="/" 
          smooth                     
        >
          <img 
            src={Logo}
            alt={`MotionAd Logo`}
            style={{
              width: 200,
            }}
          />
        </NavHashLink>
        <div className='company-phone-email-section'>
          <a className='company-phone-email' href='tel:+2347016815297'>
            +234 808 957 5760
          </a>
          <a className='company-phone-email' href='mailto:hello@motionad.africa'>
            hello@motionad.africa
          </a>
        </div>
      </div>
      <div className='footer-links-section'>
        <div className='footer-text-links'>
          {
            pathList.map((item: any, index: number) => {
              return (
                <div key={index} >
                  <NavHashLink 
                    exact to={item.url} 
                    smooth
                    className="footer-text-link"
                             
                  >
                    {item.title}
                  </NavHashLink>
                </div>
              )
            })
          }
        </div>
        <div className='footer-socials'>
          <a href='#'>
            <img 
              src={LinkedIn}
              alt='LinkedIn'
              style={{
                height: 20,
                marginRight: 20,
              }}
            />
          </a>
          <a href='#'>
            <img 
              src={Facebook}
              alt='Facebook'
              style={{
                height: 20,
                marginRight: 20,
              }}
            />
          </a>
          <a href='#'>
            <img 
              src={Instagram}
              alt='Instagram'
              style={{
                height: 20,
                marginRight: 20,
              }}
            />
          </a>
          <a href='#'>
            <img 
              src={Twitter}
              alt='Twitter'
              style={{
                height: 20,
              }}
            />
          </a>
        </div>
      </div>
      <p className='footer-rights'>
        Copyright © 2023 Perfect Jubilee Motion Advertising LTD. All rights reserved.
      </p>
    </div>
  )
}