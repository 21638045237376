import { getToken } from "../utils/handleStorage";

type fetchOptionsType = {
  method?: 'DELETE' | 'GET' | 'POST' | 'PUT' | 'PATCH';
  headers?: {
    'Content-Type'?: 'application/json' | 'text/plain' | 'text/html' | 'application/x-www-form-urlencoded' | 'multipart/form-data' | any;
    Accept?: 'application/json' | 'text/html' |  any
    Authorization?: string;
  } & any,
  body?: any;
  timeout?: number;
} & any

const defaultTimeout = 10000

export async function apiFetch(url: string, options: fetchOptionsType = {timeout: defaultTimeout, headers: {Accept: 'application/json'}}) {  
  const { timeout = defaultTimeout, method = 'GET' } = options;  

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), (timeout | defaultTimeout));
  const response = await fetch(url, {
    ...options,
    signal: controller.signal,
    method,
  });

  clearTimeout(id);
  
  return response;
}

export async function authApiFetch(url: string, options: fetchOptionsType = {timeout: defaultTimeout,  headers: {Accept: 'application/json'}}) {
  const tokens = await getToken()
  
  const { timeout = defaultTimeout,  method = 'GET', headers: { Authorization = tokens} } = options;
  
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), (timeout | defaultTimeout));
  
  
  const response = await fetch(url, {
    ...options,
    signal: controller.signal,
    method,
    headers: {
      Authorization,
      ...options?.headers,
    }
  });

  clearTimeout(id);
  
  return response;
}