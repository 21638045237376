import React, { useEffect, useState }  from "react";
// @ts-ignore
import { NavHashLink } from 'react-router-hash-link';
import { toast } from 'react-toastify';
import { 
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { useDispatch } from "react-redux";

import './AuthPage.css'
import { AuthPageProps } from "./types";

import Logo from '../../assets/svg/Logo.svg'
import countries, { getCountryViaKeyValue } from "./coutries";
import { register_client, verify_otp, web_login } from "../../api/auth";
import getErrorMessage from "../../utils/getErrorMessage";
import { saveObject, saveSecureData } from "../../utils/handleStorage";

import { logIn } from "../../redux/user";


function AuthPage(props: AuthPageProps) {
  const [inputValues, setInputValues] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    phoneNo: "",
    country: "",
    state: "",
    businessName: "",
    isFleet: false,
    role: "",
    referralCode: "",
    acceptedTerms: false,
    otp: "",
  })
  const [err, setErr] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    phoneNo: "",
    country: "",
    state: "",
    businessName: "",
    isFleet: false,
    role: "",
    referralCode: "",
    acceptedTerms: false,
    otp: "",
    default: "",
  })
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [displayType, setDisplayType] = useState<"default" | "verify-email">("default");
  const [tempID, setTempID] = useState("")
  const [otpPurpose, setOtpPurpose] = useState<"Login" | "Sign Up">("Login")

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate()
  const location = useLocation();
  const dispatch = useDispatch()

  let to = location.state?.from?.pathname || "/dashboard";

  useEffect(() => {
    if (props.action !== "verify-email") {
      setDisplayType("default")
      setOtpPurpose("Sign Up")
    }
    let code = searchParams.get("code")
    let temp_id = searchParams.get("temp_id")
    setTempID(temp_id || "")
    setInputValues({
      ...inputValues,
      otp: code || "",
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    
    setInputValues({
      ...inputValues,
      [name]: value
    });
  }

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleRegister = async () => {
    try {
      if (!inputValues.acceptedTerms) {
        toast.warn("Kindly review and accept our terms of service before signing up.", {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return
      }
      var country = getCountryViaKeyValue("name", inputValues.country)
      var params = {
        first_name: inputValues.firstName,
        last_name: inputValues.lastName,
        email: inputValues.email,
        phone_number: inputValues.phoneNo,
        password: inputValues.password,
        business_name: inputValues.businessName,
        role: inputValues.role,
        referral_code: inputValues.referralCode,
        country_code: country?.code || 'NG',
      }
      setErr({
        email: "",
        password: "",
        lastName: "",
        phoneNo: "",
        country: "",
        state: "",
        businessName: "",
        isFleet: false,
        role: "",
        referralCode: "",
        acceptedTerms: false,
        otp: "",
        default: "",
        firstName: ""
      })

      const res = await register_client(params)
      const response = await res.json()
      console.log('response', response);
      
      if (res.ok) {
        toast.success(response.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setDisplayType("verify-email")
        setTempID(response.data.temp_id)
        setOtpPurpose("Sign Up")
      } else {
        if (response?.invalid_params) {
          if (Boolean(response?.invalid_params?.length)) {
            for (let invalid_param of response?.invalid_params) {
              
              switch (invalid_param?.name) {
                case "first_name":
                  setErr((prevState: any) => {
                    return {
                      ...prevState,
                      firstName: invalid_param?.reason,
                    }
                  })
                  break;
                case "last_name":
                  setErr((prevState: any) => {
                    return {
                      ...prevState,
                      lastName: invalid_param?.reason,
                    }
                  })
                  break;
                case "email":
                  setErr((prevState: any) => {
                    return {
                      ...prevState,
                      email: invalid_param?.reason,
                    }
                  })
                  break;
                case "phone_number":
                  setErr((prevState: any) => {
                    return {
                      ...prevState,
                      phoneNo: invalid_param?.reason,
                    }
                  })
                  break;
                case "role":
                  setErr((prevState: any) => {
                    return {
                      ...prevState,
                      role: invalid_param?.reason,
                    }
                  })
                  break;
                case "business_name":
                  setErr((prevState: any) => {
                    return {
                      ...prevState,
                      businessName: invalid_param?.reason,
                    }
                  })
                  break;
                case "password":
                  setErr((prevState: any) => {
                    return {
                      ...prevState,
                      password: invalid_param?.reason,
                    }
                  })
                  break;
                case "referral_code":
                  setErr((prevState: any) => {
                    return {
                      ...prevState,
                      referralCode: invalid_param?.reason,
                    }
                  })
                  break;
                case "country":
                  setErr((prevState: any) => {
                    return {
                      ...prevState,
                      country: invalid_param?.reason,
                    }
                  })
                  break;
                default:
                  break
              }
            }
          }
        
          return
        }
        toast.error(response.detail, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const handleLogIn = async () => {
    try {
      var params = {
        email: inputValues.email,
        password: inputValues.password
      }

      const res = await web_login(params)
      const response = await res.json()
      
      if (res.ok) {
        
        toast.success(response.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        if (response.data.is_email_verified) {
          console.log('response.data',response.data);
          
          dispatch(logIn({
            isAuthenticated: true,
            userDetails: response.data.user_data?.user_details,
            businessProfile: response.data?.user_data?.business_profile || {},
          }))        
          saveSecureData("token", response.data.user_data?.token || "")  
          // Send them back to the page they tried to visit when they were
          // redirected to the login page. Use { replace: true } so we don't create
          // another entry in the history stack for the login page.  This means that
          // when they get to the protected page and click the back button, they
          // won't end up back on the login page, which is also really nice for the
          // user experience.
          navigate(to, { replace: true });
        } else {
          setDisplayType("verify-email")
          setTempID(response.data.temp_id)
          setOtpPurpose("Login")
        }
      } else {
        if (response?.invalid_params) {
          if (Boolean(response?.invalid_params?.length)) {
            for (let invalid_param of response?.invalid_params) {
              
              switch (invalid_param?.name) {
                case "email":
                  setErr((prevState: any) => {
                    return {
                      ...prevState,
                      email: invalid_param?.reason,
                    }
                  })
                  break;
                case "password":
                  setErr((prevState: any) => {
                    return {
                      ...prevState,
                      password: invalid_param?.reason,
                    }
                  })
                  break;
                default:
                  break
              }
            }
          }
        
          return
        }
        toast.error(response.detail, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const handleVerifyOtp = async () => {
    try {
      var params = {
        temp_id: tempID,
        code: inputValues.otp,
        purpose: otpPurpose,
        platform: 'web',
      }
      
      const res = await verify_otp(params)
      const response = await res.json()
      
      if (res.ok) {
        
        toast.success(response.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        dispatch(logIn({
          isAuthenticated: true,
          userDetails: response.data.user_details,
          businessProfile: response.data?.business_profile || {},
        }))     
        saveSecureData("token", response.data.user_data?.token || "")       
        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.
        navigate(to, { replace: true });
      } else {
        
        if (Boolean(response?.invalid_params)) {
          if (Boolean(response?.invalid_params?.length)) {
            for (let invalid_param of response?.invalid_params) {
              switch (invalid_param?.name) {
                case "otp":
                  setErr((prevState: any) => {
                    return {
                      ...prevState,
                      otp: invalid_param?.reason,
                    }
                  })
                  break;
                default:
                  break
              }
            }
          }
        
          return
        }
        
        
        toast.error(response.detail, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      toast.error(getErrorMessage(error), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      setIsSubmitting(true)
      if (displayType === 'verify-email'){
        await handleVerifyOtp()
        setIsSubmitting(false)
        return
      }
      switch (props.action) {
        case "login":
          await handleLogIn()
          setIsSubmitting(false)
          break;
        case "sign-up":
          await handleRegister()
          setIsSubmitting(false)
          break;
        case "verify-email":
          await handleVerifyOtp()
          setIsSubmitting(false)
          break;
        
        default:
          toast('🦄 Wow so easy?', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setIsSubmitting(false)
          break;
      }
      
    } catch (error) {
      toast.error("Oops... Something went wrong", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  

  return (
    <div className="auth-page-container">
      <div className="auth-page-header">
        <div/>
        <NavHashLink
          exact to="/" 
          smooth                     
        >
          <img 
            src={Logo}
            alt={`MotionAd Logo`}
            className="auth-header-logo"
          />
        </NavHashLink>
        <NavHashLink 
          exact to={props.action === 'login' ? "/sign-up" : "/login"} 
          smooth
          className="auth-header-btn"   
        >
          {props.action === 'login' ? "Sign Up" : "Login"}
        </NavHashLink>
      </div>
      {
        (displayType === 'default' && props.action === "sign-up") ? (
          <>
            <div className="auth-page-form-container">
              <h2>
                Create your MotionAd account
              </h2>

              <form autoComplete="on">
                <span className="input-group">
                  <label className="auth-page-input-label">First Name</label>
                  <input 
                    onChange={handleInputChange} 
                    type="text"
                    value={inputValues.firstName}
                    required
                    name="firstName"
                    className="auth-page-input"
                  />
                  {
                    Boolean(err.firstName.length) && (
                      <p className="auth-input-err-msg">
                        {err.firstName}
                      </p>
                    )
                  }
                </span>
                <span className="input-group">
                  <label className="auth-page-input-label">Last Name</label>
                  <input 
                    onChange={handleInputChange} 
                    type="text"
                    value={inputValues.lastName}
                    required
                    name="lastName"
                    className="auth-page-input"
                  />
                  {
                    Boolean(err.lastName.length) && (
                      <p className="auth-input-err-msg">
                        {err.lastName}
                      </p>
                    )
                  }
                </span>
                <span className="input-group">
                  <label className="auth-page-input-label">Email</label>
                  <input 
                    onChange={handleInputChange} 
                    type="email"
                    value={inputValues.email}
                    required
                    name="email"
                    className="auth-page-input"
                  />
                  {
                    Boolean(err.email.length) && (
                      <p className="auth-input-err-msg">
                        {err.email}
                      </p>
                    )
                  }
                </span>
                <span className="input-group">
                  <label className="auth-page-input-label">Phone Number</label>
                  <input 
                    onChange={handleInputChange} 
                    type="tel"
                    value={inputValues.phoneNo}
                    required
                    name="phoneNo"
                    className="auth-page-input"
                  />
                  {
                    Boolean(err.phoneNo.length) && (
                      <p className="auth-input-err-msg">
                        {err.phoneNo}
                      </p>
                    )
                  }
                </span>
                <span className="input-group">
                  <label className="auth-page-input-label">Business Name</label>
                  <input 
                    onChange={handleInputChange} 
                    type="text"
                    value={inputValues.businessName}
                    required
                    name="businessName"
                    className="auth-page-input"
                  />
                  {
                    Boolean(err.businessName.length) && (
                      <p className="auth-input-err-msg">
                        {err.businessName}
                      </p>
                    )
                  }
                </span>
                <span className="input-group">
                  <label className="auth-page-input-label">Role</label>
                  <input 
                    onChange={handleInputChange} 
                    type="text"
                    value={inputValues.role}
                    required
                    name="role"
                    className="auth-page-input"
                  />
                  {
                    Boolean(err.role.length) && (
                      <p className="auth-input-err-msg">
                        {err.role}
                      </p>
                    )
                  }
                </span>
                <span className="input-group">
                  <label className="auth-page-input-label">Country</label>                 
                  <select 
                    value={inputValues.country} 
                    onChange={handleInputChange} 
                    name='country'
                    className="auth-page-input"
                  >
                    <option disabled value="">-- Select Country--</option>
                    {
                      countries.map((item: typeof countries[0], index: number) => {
                        return (
                          <option value={item.name}>{item.name}</option>
                        )
                      })
                    }
                  </select>
                  {
                    Boolean(err.country.length) && (
                      <p className="auth-input-err-msg">
                        {err.country}
                      </p>
                    )
                  }
                </span>
                <span className="input-group">
                  <label className="auth-page-input-label">Referral Code (Optional)</label>
                  <input 
                    onChange={handleInputChange} 
                    type="text"
                    value={inputValues.referralCode}
                    required
                    name="referralCode"
                    className="auth-page-input"
                  />
                  {
                    Boolean(err.referralCode.length) && (
                      <p className="auth-input-err-msg">
                        {err.referralCode}
                      </p>
                    )
                  }
                </span>
                <span className="input-group">
                  <label className="auth-page-input-label">Password</label>
                  <span className="auth-page-password-input-group">
                    <input 
                      onChange={handleInputChange} 
                      type={showPassword ? 'text' : 'password'}
                      value={inputValues.password}
                      required
                      name="password"
                      className="auth-page-password-input"
                    />
                    <p onClick={handleTogglePassword}>
                      {showPassword ? 'Hide' : 'Show'}
                    </p>
                  </span>
                  {
                    Boolean(err.password.length) && (
                      <p className="auth-input-err-msg">
                        {err.password}
                      </p>
                    )
                  }
                </span>
                <span className="terms-input-group">
                  <input 
                    type="checkbox"
                    onChange={handleInputChange} 
                    // @ts-ignore
                    value={inputValues.acceptedTerms}
                    name="acceptedTerms"
                  />
                  <p>
                    Click here to accept our  <NavHashLink exact to={"/terms"} >Terms of Services</NavHashLink>
                  </p>
                </span>
                <button className="auth-page-btn" type="submit" onClick={handleSubmit}>
                  Sign Up
                </button>
              </form>        
            </div>
            <div className="verify-action">
              <p>
                Already have an account?
              </p>
              <NavHashLink 
                exact to={"/login"} 
                smooth
                className="verify-action-btn"   
              >
                Login
              </NavHashLink>
            </div>
          </>
        ) : null
      }
      {
        (displayType === 'default' && props.action === "login") ? (
          <>
            <div className="auth-page-form-container">
              <h2>
                Log In
              </h2>

              <form autoComplete="on">
                <span className="input-group">
                  <label className="auth-page-input-label">Email</label>
                  <input 
                    onChange={handleInputChange} 
                    type="email"
                    value={inputValues.email}
                    required
                    name="email"
                    className="auth-page-input"
                  />
                  {
                    Boolean(err.email.length) && (
                      <p className="auth-input-err-msg">
                        {err.email}
                      </p>
                    )
                  }
                </span>
                <span className="input-group">
                  <label className="auth-page-input-label">Password</label>
                  <span className="auth-page-password-input-group">
                    <input 
                      onChange={handleInputChange} 
                      type={showPassword ? 'text' : 'password'}
                      value={inputValues.password}
                      required
                      name="password"
                      className="auth-page-password-input"
                    />
                    <p onClick={handleTogglePassword}>
                      {showPassword ? 'Hide' : 'Show'}
                    </p>
                  </span>
                  {
                    Boolean(err.password.length) && (
                      <p className="auth-input-err-msg">
                        {err.password}
                      </p>
                    )
                  }
                </span>
                <button className="auth-page-btn" type="submit" onClick={handleSubmit} disabled={isSubmitting}>
                  Log In
                </button>
              </form>        
            </div>
            <div className="verify-action">
              <p>
                Don't have an account?
              </p>
              <NavHashLink 
                exact to={"/sign-up"} 
                smooth
                className="verify-action-btn"   
              >
                Sign Up
              </NavHashLink>
            </div>
          </>
        ) : null
      }
      {
        (displayType === 'verify-email' || props.action === "verify-email") ? (
          <>
            <div className="auth-page-form-container">
              <h2>
                Verify Email Address
              </h2>

              <form autoComplete="on">
                <span className="input-group">
                  <label className="auth-page-input-label">Enter the OTP sent to your email</label>
                  <input 
                    onChange={handleInputChange} 
                    type="text"
                    value={inputValues.otp}
                    name="otp"
                    required
                    className="auth-page-input"
                  />
                  {
                    Boolean(err.otp.length) && (
                      <p className="auth-input-err-msg">
                        {err.otp}
                      </p>
                    )
                  }
                </span>
                <button className="auth-page-btn" type="submit" onClick={handleSubmit} disabled={isSubmitting}>
                  Submit
                </button>
              </form>        
            </div>
          </>
        ) : null
      }
      
    </div>
  )
}

export default AuthPage