import React, { useState }  from "react";
import { NavLink, useLocation } from 'react-router-dom'
// @ts-ignore
import { NavHashLink } from 'react-router-hash-link';

import "./NavBar.css"

import CloseMenu from '../../assets/svg/CloseIcon.svg'
import OpenMenu from '../../assets/svg/DrawerIcon.svg'
import Logo from '../../assets/svg/Logo.svg'

import { NavBarProps } from "./types";


function NavBar(props: NavBarProps) {
  const [is_menu_icon_clicked, setMenuIconClicked] = useState(false);
  const location = useLocation()


  const handleMenuIconClick = () => {
    setMenuIconClicked(!is_menu_icon_clicked);
  };

  const closeMenu = async () => {
    
    setMenuIconClicked(false)
    props.closeGetStartedModal()
    // await sleep(1000)
  }

  const MenuList = [
    {
      title: 'Home',
      url: '/'
    },
    {
      title: 'Partners',
      url: '/partners'
    },
    {
      title: 'Portfolio',
      url: '/portfolio'
    },
    {
      title: 'About',
      url: '/about'
    },
    {
      title: 'Contact Us',
      url: '/contact-us'
    },
  ]


  const menuList = MenuList.map((item, index, array) => {
    return (
      <div key={index} >
        <NavHashLink 
          exact to={item.url} 
          smooth
          className={`${location.pathname}${location.hash}` === item.url ? "nav-link-active" : "nav-link"}
          onClick={closeMenu}            
        >
          {item.title}
        </NavHashLink>
      </div>
    )
    
  })


  return(
    <nav>
      <div className="desktop-nav">
        <NavHashLink 
          exact to="/" 
          smooth                     
        >
          <img 
            src={Logo}
            alt={`MotionAd Logo`}
            style={{
              width: 160,
            }}
          />
        </NavHashLink>
        
        {
          props?.showLinks ? (
            <div className={is_menu_icon_clicked ? "menu-list" : "menu-list close"}>
              {menuList}        
            </div>
          ) : (
            <div style={{flex: 1}} />
          )
        }
       
        <NavHashLink 
          exact to={"/login"} 
          smooth
          className={`${location.pathname}${location.hash}` === "/login" ? "nav-link-active" : "nav-link"}
          onClick={closeMenu}            
        >
          Login
        </NavHashLink>
        <div className="nav-ctas">
          <div 
            className="btn nav-cta-get-started" 
            onClick={props.openGetStartedModal}>
            <p>Get Started</p>
          </div>
        </div>
      </div>

      <div className="mobile-nav">
        <NavHashLink 
          exact to="/" 
          smooth                     
        >
          <img 
            src={Logo}
            alt={`MotionAd Logo`}
            style={{
              width: 140,
            }}
          />
        </NavHashLink>
        
        {
          props?.showLinks ? (
            <div className={is_menu_icon_clicked ? "menu-list" : "menu-list close"}>        
              {menuList}  
              <NavHashLink 
                exact to={"/login"} 
                smooth
                className={`${location.pathname}${location.hash}` === "/login" ? "nav-link-active m-login" : "nav-link m-login"}
                onClick={closeMenu}         
                style={{
                  paddingLeft: 20,
                }}   
              >
                Login
              </NavHashLink>
              
              <div className="nav-ctas">
              
                <div 
                  className="btn nav-cta-get-started" 
                  onClick={() => {
                    closeMenu()
                    if (props.openGetStartedModal) {
                      props?.openGetStartedModal()
                    }
                    
                  }}
                >
                  <p>Get Started</p>
                </div>
              </div>
            </div>
          ) : (
            <div 
              className="btn nav-cta-get-started" 
              onClick={() => {
                closeMenu()
                if (props.openGetStartedModal) {
                  props?.openGetStartedModal()
                }
                
              }}
            >
              <p>Get Started</p>
            </div>
          )
        }
        
        
        {/* <ul className={is_menu_icon_clicked ? "menu-list" : "menu-list close"}>
          {menuList}
        </ul> */}
        {
          props?.showLinks && (
            <div className="menu-icon" onClick={handleMenuIconClick}>
              <img
                src={is_menu_icon_clicked ? CloseMenu : OpenMenu}
                alt='Menu Icon'
              />
            </div>
          )
        }
        
      </div>

      
    </nav>
  )
}

export default NavBar

NavBar.defaultProps = {
  showLinks: true
}