import '../Pages.css'
import './AboutUs.css'

import Car4 from '../../assets/svg/car-mockup4.svg'
import BG from '../../assets/svg/bg-2.svg'
import Footer from '../../components/Footer'
import { useRef } from 'react'
import { GetStartedModalType } from '../../components/GetStartedModal/types'
import NavBar from '../../components/NavBar/NavBar'
import GetStartedModal from '../../components/GetStartedModal'

export default function AboutUs() {
  const getStartedModalRef = useRef<GetStartedModalType>()
  return (
    <div className='about-us-hero'>
      <NavBar
          openGetStartedModal = {() => getStartedModalRef?.current?.openModal()}
          closeGetStartedModal = {() => getStartedModalRef?.current?.closeModal()}
        />     
      <GetStartedModal
        ref={getStartedModalRef}
      />  
      <h1>
        About Us
      </h1>
      <div className='about-us-hero-contents'>
        <div className='about-us-hero-contents-text'>
        We constantly on the look out to add on more benefits for our driver, so if you have anything in mind you’d like us to add, contact us. We constantly on the look out to add on more benefits for our driver, so if you have anything in mind you’d like us to add, contact us. We constantly on the look out to add on more benefits for our driver, so if you have anything in mind you’d like us to add, contact us
        </div>
        <div className='about-us-hero-contents-image'>
          <img 
            src={Car4}
            alt='Car with advert'
          />
        </div>
      </div>
      <img 
        src={BG}
        alt='Base BG'
      />
      <Footer />
    </div>
  )
}