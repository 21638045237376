import React, {useRef} from 'react';
import { 
  BrowserRouter as Router, 
  Routes, 
  Route, 
  Navigate, 
  useLocation 
} from "react-router-dom";
import './App.css';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import {Provider} from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import {store, persistor} from './redux/store'


import AboutUs from './pages/AboutUs';
import AuthPage from './pages/AuthPage';
import CreateCampaign from './pages/CreateCampaign';
import Dashboard from './pages/Dashboard';
import Home from './pages/Home';
import Terms from './pages/Terms';
import Partner from './pages/Partner';
import { DefaultState } from './redux/types';
import ScrollToTop from './components/ScrollToTop';
import ContactUs from './pages/ContactUs';
import LandingPage001 from './pages/LandingPages/LandingPage001';


function RequireAuth({ children }: {children: any}) {
  const user = useSelector((state: DefaultState) => state.user);
  
  let location = useLocation();

  if (!user.isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function AppNav () {
  const user = useSelector((state: DefaultState) => state.user);  

  return (
    <div className="container">      
      <Router>          
        <ScrollToTop />
        <Routes>            
          <Route path="/" element={<Home />} />
          <Route path="/partners" element={<Partner />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/landing001" element={<LandingPage001 />} />
          <Route path="/login" element={<AuthPage action="login" />} />
          <Route path="/sign-up" element={<AuthPage action="sign-up" />} />
          <Route path="/verify-email" element={<AuthPage action="verify-email" />} />
          <Route 
            path="/dashboard" 
            element={
              <RequireAuth>
                <Dashboard/>
              </RequireAuth>
            } 
          />   
          <Route 
            path="/create-campaign" 
            element={
              <RequireAuth>
                <CreateCampaign/>
              </RequireAuth>
            } 
          />   

          {/* change the "/" path here to page not found screen */}
          <Route path="*" element={<Navigate to={user.isAuthenticated ? "/dashboard" : "/"} />} />      
        </Routes>
        
      </Router>   
      <ToastContainer />
    </div>
  )
}

function App() {

  return (
    <Provider store={store}>
      <PersistGate 
        loading={null}
        persistor={persistor}
      >
        <AppNav />
      </PersistGate>
    </Provider>
  );
}

export default App;
