import React, { useRef, useState }  from "react";
import { toast } from 'react-toastify';

import '../Pages.css'
import './ContactUs.css'

import Car4 from '../../assets/svg/car-mockup4.svg'
import BG from '../../assets/svg/bg-2.svg'
import Footer from '../../components/Footer'
import NavBar from '../../components/NavBar/NavBar'
import GetStartedModal from '../../components/GetStartedModal'
import { GetStartedModalType } from "../../components/GetStartedModal/types";
import { validateEmail } from "../../utils/validateEmail";
import LoadingModal from "../../components/LoadingModal";
import { send_contact_form } from "../../api/notifications";

export default function ContactUs (props: any) {
  const [inputValues, setInputValues] = useState({
    email: "",
    message: "",
    fullName: "",
    phoneNo: "",
  })
  const [err, setErr] = useState({
    email: "",
    message: "",
    fullName: "",
    phoneNo: "",
  })
  const [isSubmitting, setIsSubmitting] = useState(false);

  const loadingModalRef = useRef<any>()
  const getStartedModalRef = useRef<GetStartedModalType>()

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    
    setInputValues({
      ...inputValues,
      [name]: value
    });
  }

  const validateForm = () => {
    var isValid = true

    if (!Boolean(inputValues.fullName.length) || inputValues.fullName.length < 3) {   
      isValid = false   
      setErr((prevState: any) => ({
        ...prevState,
        fullName: "Kindly provide a valid full name"
      }))
    }

    if (!Boolean(inputValues.message.length) || inputValues.message.length < 10) {   
      isValid = false   
      setErr((prevState: any) => ({
        ...prevState,
        message: "Kindly provide a message of 10 characters or more"
      }))
    }

    if (Boolean(inputValues.email.length)) {
      if (!validateEmail(inputValues.email)) {
        isValid = false
        setErr((prevState: any) => ({
          ...prevState,
          email: "Kindly provide a valid email address"
        }))
      }
    } else {
      isValid = false
      setErr((prevState: any) => ({
        ...prevState,
        email: "Kindly provide a valid email address"
      }))
    }


    return isValid
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      loadingModalRef.current.showLoadingModal()
      setIsSubmitting(true)
      if (!validateForm()) {
        loadingModalRef.current?. hideLoadingModal()
        setIsSubmitting(false)
        return
      }

      var params = {
        email: inputValues.email,
        message: inputValues.message,
        full_name: inputValues.fullName,
        phone_number: inputValues.phoneNo,
      }

      const res = await send_contact_form(params) 
      const response = await res.json()
      console.log('response', response);
      
      if (res.ok){
        
        setInputValues({
          ...inputValues,
          email: "",
          fullName: "",
          message: "",
          phoneNo: '',
        });
        loadingModalRef.current?. hideLoadingModal()
        toast.success(response.message || "Success", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        loadingModalRef.current?. hideLoadingModal()
        toast.error(response.message || "Oops... Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      
    } catch (error) {
      
    }
  }

  return (
    <div className='motionad-contact-us-page-container'>
      <NavBar
          openGetStartedModal = {() => getStartedModalRef?.current?.openModal()}
          closeGetStartedModal = {() => getStartedModalRef?.current?.closeModal()}
        />     
      <GetStartedModal
        ref={getStartedModalRef}
      />  
      <div className="motionad-contact-page-hero">  
        <div className="motionad-contact-page-hero-form-content">
          <h1 className="motionad-contact-page-hero-form-content-h1">
            Contact Us
          </h1>
          <p className="motionad-contact-page-hero-form-heading-desc">We’re looking forward to promote your brand</p>
          <form autoComplete="on">
            <span className="input-group">
              <label className="auth-page-input-label">Full Name</label>
              <input 
                onChange={handleInputChange} 
                type="text"
                value={inputValues.fullName}
                required
                name="fullName"
                className="auth-page-input"
              />
              {
                Boolean(err.fullName.length) && (
                  <p className="auth-input-err-msg">
                    {err.fullName}
                  </p>
                )
              }
            </span>
            <span className="input-group">
              <label className="auth-page-input-label">Email</label>
              <input 
                onChange={handleInputChange} 
                type="email"
                value={inputValues.email}
                required
                name="email"
                className="auth-page-input"
              />
              {
                Boolean(err.email.length) && (
                  <p className="auth-input-err-msg">
                    {err.email}
                  </p>
                )
              }
            </span>
            <span className="input-group">
              <label className="auth-page-input-label">Phone Number</label>
              <input 
                onChange={handleInputChange} 
                type="tel"
                value={inputValues.phoneNo}
                required
                name="phoneNo"
                className="auth-page-input"
              />
              {
                Boolean(err.phoneNo.length) && (
                  <p className="auth-input-err-msg">
                    {err.phoneNo}
                  </p>
                )
              }
            </span>
            <span className="input-group">
              <label className="auth-page-input-label">Message</label>
              <textarea 
                onChange={handleInputChange} 
                value={inputValues.message}
                required
                name="message"
                // className="auth-page-input"
              />
              {
                Boolean(err.message.length) && (
                  <p className="auth-input-err-msg">
                    {err.message}
                  </p>
                )
              }
            </span>
            <button className="auth-page-btn" type="submit" onClick={handleSubmit}>
              Submit
            </button>
          </form>
        </div>
        <div className='motionad-contact-page-hero-image'>
          <img 
            src={Car4}
            alt='Car with advert'
          />
        </div>
      </div>
      <Footer />
      <LoadingModal ref={loadingModalRef} />
    </div>
  )
}