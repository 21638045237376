import React, { forwardRef, useImperativeHandle, useState } from 'react'
import Lottie from "lottie-react";
// @ts-ignore
import Modal from 'react-modal';

import './LoadingModal.css'
import { LoadingModalProps } from './type'
import Loading from '../../assets/animation/loading0.json'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    zIndex: 999999999,
    transform: 'translate(-50%, -50%)',
    borderRadius: 24,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.24)',
    zIndex: 99999999,
  },
};

const LoadingModal = forwardRef((props: LoadingModalProps, ref) => {
  const [modalIsOpen, setIsModalOpen] = useState(false);

  const showLoadingModal = () => {
    setIsModalOpen(true)
  }

  const hideLoadingModal = () => {
    setIsModalOpen(false)
  }

  useImperativeHandle(ref, () => ({
    hideLoadingModal: hideLoadingModal,
    showLoadingModal: showLoadingModal,
  }))

  return (
    <Modal
      isOpen={modalIsOpen}
      // onRequestClose={hideLoadingModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div>
        <Lottie 
          animationData={Loading} 
          loop={true} 
          style={{
            width: 160,
            height: 160,
          }}
        />
      </div>
    </Modal>
  )
})

export default LoadingModal