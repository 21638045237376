
import React, {
  useEffect,
  useState,
} from  'react'
import { toast } from 'react-toastify';

import "./CreateCampaign.css"
import { CreateCampaignPropsType } from "./types"
import VerticalNavBar from '../../components/VerticalNavBar'
import VehicleCard from '../../components/VehicleCard'

import Car0 from '../../assets/images/ car-1.png'
import { get_region_options, get_camp_options, get_regions } from '../../api/campaign'
import { BASE_URL } from '../../env';

type PagesType = "page-0" | "page-1" | "page-2"
const Vehicles = [0,1,2]

export default function CreateCampaign(props: CreateCampaignPropsType) {
  const [displayType, setDisplayType] = useState<PagesType>("page-0")
  const [desc, setDesc] = useState("Select campaign region")
  const [selectVehicle, setSelectVehicle] = useState(-1)
  const [inputValues, setInputValues] = useState({
    title: "",
    duration: 1,
    noOfVehicle: 1,
    route: "",
    vehicleType: "",
    campaignPeriod: "",
    country: "",
    region: "",
  })
  const [errMsg, setErrMsg] = useState({
    title: "",
    duration: '',
    noOfVehicle: '',
    route: "",
    vehicleType: "",
    campaignPeriod: "",
    country: "",
    region: "",
  })
  const [loading, setLoading] = useState({
    options: false,
    regions: false,
    regionOptions: false,
  })
  const [options, setOptions] = useState<any>({})

  useEffect(() => {
    getOptions()
  }, [])

  const getRegionOptions = async (regionCode: string) => {
    try {
      setLoading({
        ...loading,
        regionOptions: true
      })
      const res = await  get_region_options(regionCode)
      const response = await res.json()
      console.log('response', response);
      
      if (res.ok) {

        setOptions({
          ...options,  
          ...response.data
        })
      }
    } catch (error) {
    
    }
    setLoading({
      ...loading,
      regionOptions: false
    })
  }

  const getOptions= async () => {
    try {
      
      setLoading({
        ...loading,
        options: true
      })
      const res = await get_camp_options()
      const response = await res.json()
      console.log('getOptions', response);
      
      if (res.ok) {

        setOptions({
          ...options,  
          ...response.data
        })
      }
      setLoading({
        ...loading,
        options: false
      })
    } catch (error) {
    
    }
    setLoading({
      ...loading,
      options: false
    })
  }

  const getCountryRegion = async (countryCode: string) => {
    try {
      setLoading({
        ...loading,
        regions: true
      })
      const res = await get_regions(countryCode)
      if (res.ok) {
        const response = await res.json()
        setOptions({
          ...options,  
          regions: response.data.regions
        })
      } else {
        setOptions({
          ...options,  
          regions:[]
        })
      }
    } catch (error) {
      
    }
    setLoading({
      ...loading,
      regions: false
    })
  }

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    
    setInputValues({
      ...inputValues,
      [name]: value
    });
  }

  const selectCountry = async (event: any) => {    
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setInputValues(prevState => {
      return {
        ...prevState,
        country: value,
      }
    })
    setErrMsg(prevState => {
      return {
        ...prevState,
        country: "",
      }
    })
    await getCountryRegion(target.selectedOptions[0].dataset.extraData)
  }

  const selectRegion = async (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setInputValues(prevState => {
      return {
        ...prevState,
        region: value,
      }
    })
    setErrMsg(prevState => {
      return {
        ...prevState,
        region: "",
      }
    })

    await getRegionOptions(target.selectedOptions[0].dataset.extraData)

  }

  const handleVehicleChange = async (value: string) => {    
    setInputValues(prevState => {
      return {
        ...prevState,
        vehicleType: value,
      }
    })
    setErrMsg(prevState => {
      return {
        ...prevState,
        vehicleType: "",
      }
    })
  }



  const switchPage = (page: PagesType) => {
    if (page === "page-1") {
      if (Boolean(inputValues.country.length) || Boolean(inputValues.region)) {
        setDesc("Select a vehicle type to convey your campaign")
        setDisplayType(page)
      } else {
        toast.warn("Kindly select campaign country and region", {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      }
      
    } else if (page === 'page-2') {
      if (Vehicles.includes(selectVehicle)) {
        setDisplayType(page)
        return
      } else {
        toast.warn("Kindly select a vehicle type to convey your campaign", {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return
      }
    } else if (page === "page-0") {
      setDesc("Select campaign region")
      setDisplayType(page)
      return
    }
  }

  return (
    <div className='create-campaign-container'>
      <VerticalNavBar />
      <div className='create-campaign-contents-section'>
        <h2 className='create-campaign-title'>
          Create New Campaign
        </h2>
        <p className='create-campaign-desc'>
          {desc}
        </p>
        <div className='pagination-group'>
          <div 
            className={displayType === 'page-0' ? "active-indicator" : "indicator"}
          />
          <div 
            className={displayType === 'page-1' ? "active-indicator" : "indicator"}
            style={{
              marginLeft: 10,
            }}
          />
          <div 
            className={displayType === 'page-2' ? "active-indicator" : "indicator"}
            style={{
              marginLeft: 10,
            }}
          />
        </div>

        {
          displayType === "page-0" ? (
            <div className='create-campaign-page-0-contents'>
            <form
              className='form-container'
            >
              <span className="input-group" >
                <label className="auth-page-input-label">Country</label>                 
                <select 
                  value={inputValues.country} 
                  onChange={selectCountry} 
                  name='country'
                  required
                  className="auth-page-input"
                >
                  <option disabled value="">-- Select Country--</option>
                  {
                    Boolean(options?.countries) && options?.countries.map((item: any, index: number) => {
                      return (
                        <option 
                          value={item.name} 
                          key={index}
                          data-extra-data={item.iso_code}
                        >
                            {item.name}
                        </option>
                      )
                    })
                  }
                </select>
                {
                  Boolean(errMsg.country.length) && (
                    <p className="auth-input-err-msg">
                      {errMsg.country}
                    </p>
                  )
                }
              </span>
              <span className="input-group">
                <label className="auth-page-input-label">Region</label>                 
                <select 
                  required
                  value={inputValues.region} 
                  onChange={selectRegion} 
                  name='region'
                  className="auth-page-input"
                >
                  <option disabled value="">-- Select Region--</option>
                  {
                    Boolean(options?.regions) && options?.regions.map((item: any, index: number) => {
                      return (
                        <option 
                          value={item.name} 
                          key={index}
                          data-extra-data={item.iso_code}
                        >
                            {item.name}
                        </option>
                      )
                    })
                  }
                </select>
                {
                  Boolean(errMsg.country.length) && (
                    <p className="auth-input-err-msg">
                      {errMsg.country}
                    </p>
                  )
                }
              </span>
              <button className="auth-page-btn" type="submit" onClick={() => switchPage("page-1")}>
                Next
              </button>
            </form>
            </div>
          ) : null
        }

        {
          displayType === "page-1" ? (
            <div className='create-campaign-page-1-contents'>
              <div className='vehicle-types'>
                {
                  Boolean(options?.vehicle_types?.length) && options?.vehicle_types.map((item: any, index: number) => {
                    return (
                      <VehicleCard 
                        vehicleType={item}
                        isSelected={selectVehicle === index}
                        index={index}
                        onSelect={(index: number) => setSelectVehicle(index)}
                        style={{
                          marginRight: 20,
                          marginLeft: 20,
                          marginTop: 50,
                        }}
                      />
                    )
                  })
                }
              </div>
              
              <div className='create-campaign-btn-group'>
                <button
                  onClick={() => switchPage("page-0")}
                  className='create-campaign-btn-1'
                >
                  Back
                </button>
                <button
                  onClick={() => switchPage("page-2")}
                  className='create-campaign-btn'
                >
                  Next
                </button>
              </div> 
              
            </div>
          ) : null
        }

        {
          displayType === 'page-2' ? (
            <div className='create-campaign-page-1-contents'>
              <form autoComplete="on" className='form-container'>
                <span className="input-group">
                  <label className="auth-page-input-label">Title</label>
                  <input 
                    onChange={handleInputChange} 
                    type="text"
                    value={inputValues.title}
                    required
                    name="title"
                    className="auth-page-input"
                  />
                  {
                    Boolean(errMsg.title.length) && (
                      <p className="auth-input-err-msg">
                        {errMsg.title}
                      </p>
                    )
                  }
                </span>
                <span className="input-group">
                  <label className="auth-page-input-label">Campaign Duration <label style={{color: '#3F3F3F', fontSize: 10}}>(in months)</label></label>
                  <input 
                    onChange={handleInputChange} 
                    type="number"
                    min={1}
                    value={inputValues.duration}
                    required
                    name="duration"
                    className="auth-page-input"
                  />
                  {
                    Boolean(errMsg.duration.length) && (
                      <p className="auth-input-err-msg">
                        {errMsg.duration}
                      </p>
                    )
                  }
                </span>
                <span className="input-group">
                  <label className="auth-page-input-label">No of Vehicles</label>
                  <input 
                    onChange={handleInputChange} 
                    type="number"
                    min={1}
                    value={inputValues.noOfVehicle}
                    required
                    name="noOfVehicle"
                    className="auth-page-input"
                  />
                  {
                    Boolean(errMsg.noOfVehicle.length) && (
                      <p className="auth-input-err-msg">
                        {errMsg.noOfVehicle}
                      </p>
                    )
                  }
                </span>
                {
                  Boolean(options?.working_periods?.length) && (
                    <span className="input-group" >
                      <label className="auth-page-input-label">Campaign Period</label>                 
                      <select 
                        value={inputValues.campaignPeriod} 
                        onChange={handleInputChange} 
                        name='campaignPeriod'
                        required
                        className="auth-page-input"
                      >
                        {
                          Boolean(options?.working_periods) && options?.working_periods.map((item: any, index: number) => {
                            return (
                              <option 
                                value={item} 
                                key={index}
                                data-extra-data={item}
                              >
                                {item}
                              </option>
                            )
                          })
                        }
                      </select>
                      {
                        Boolean(errMsg.campaignPeriod.length) && (
                          <p className="auth-input-err-msg">
                            {errMsg.campaignPeriod}
                          </p>
                        )
                      }
                    </span>
                  )
                }
                
                {
                  (selectVehicle >= 0 && options?.vehicle_types[selectVehicle].requires_route) && (
                    <span className="input-group" >
                      <label className="auth-page-input-label">Campaign Route</label>                 
                      <select 
                        value={inputValues.route} 
                        onChange={handleInputChange} 
                        name='route'
                        required
                        className="auth-page-input"
                      >
                        {
                          Boolean(options?.campaign_routes) && options?.campaign_routes.map((item: any, index: number) => {
                            return (
                              <option 
                                value={item.name} 
                                key={index}
                                data-extra-data={item.name}
                              >
                                {item.name}
                              </option>
                            )
                          })
                        }
                      </select>
                      {
                        Boolean(errMsg.route.length) && (
                          <p className="auth-input-err-msg">
                            {errMsg.route}
                          </p>
                        )
                      }
                    </span>
                  )
                }
                 <div className='create-campaign-btn-group'>
                  <button
                    onClick={() => switchPage("page-1")}
                    className='create-campaign-btn-1'
                  >
                    Back
                  </button>
                  <button
                    type='submit'
                    className='create-campaign-btn'
                  >
                    Submit
                  </button>
              </div> 
              </form>
            </div>
          ) : null
        }
      </div>      
    </div>
  )
}