import { BASE_URL } from "../env"
import { apiFetch } from "./utils"

export type RegisterParams = {
  first_name: string,
  last_name: string,
  email: string,
  phone_number: string,
  role: string,
  business_name: string,
  country_code: string,
  referral_code: string,
  password: string,
}

export const  register_client = async (params: RegisterParams) => {
  try {
    const url = BASE_URL + '/api/account/register_client'
    return await apiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}

export type LoginParams = {
  email: string,
  password: string,
}

export const web_login = async (params: LoginParams) => {
  try {
    const url = BASE_URL + '/api/account/web_login'
    return await apiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}

export type VerifyOtpParams = {
  code: string,
  temp_id: string,
  purpose: string,
  platform: string,
}

export const verify_otp = async (params: VerifyOtpParams) => {
  try {
    const url = BASE_URL + '/api/account/verify_otp'
    return await apiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}