import './FAQItem.css'
import { FAQItemPropType } from "./types";

import ArrowDown from '../../assets/svg/arrow_down.svg'

export default function FAQItem(props: FAQItemPropType){
  
  const toggleFaq = () => {
    if (props.activeCurrentIndex === props.index) {
      props.setActiveCurrentIndex(-1)
    } else {
      props.setActiveCurrentIndex(props.index)
    }
  }

  return (
    <div className="faq-item-container" onClick={toggleFaq}>
      <div>
        <p className="faq-item-title">
          {props.title}
        </p>
        {
          (props.activeCurrentIndex === props.index) ? (
            <p className="faq-item-desc">
              {props.description}
            </p>
          ) : null
        }
      </div>
      <div>
        <img 
          src={ArrowDown}
          alt="Arrow down"
          style={{
            width: 30,
          }}
        />
      </div>
    </div>
  )
}