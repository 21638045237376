import React, {
  useRef,
  useState,
} from "react";
// @ts-ignore
import { NavHashLink } from 'react-router-hash-link';

import '../Pages.css'
import './LandingPages.css'

import Car1 from '../../assets/svg/car-mockup1.svg'
import Car2 from '../../assets/svg/car-mockup2.svg'
import CarIcon from '../../assets/images/caricon.png'
import PilotImage from '../../assets/images/pilot-1.png'
import FAQItem from "../../components/FAQItem/FAQItem";
import NavBar from "../../components/NavBar/NavBar";
import GetStartedModal from "../../components/GetStartedModal";
import { GetStartedModalType } from "../../components/GetStartedModal/types";
import Footer from "../../components/Footer";

function LandingPage001() {
  const [activeCurrentIndex, setActiveCurrentIndex] = useState(-1)
  const getStartedModalRef = useRef<GetStartedModalType>()


  const faqs = [
    {
      title: "How does Motionad work?",
      description: "Motionad connects brands with cab and commercial vehicle owners, allowing brands to place advertisements on these vehicles for maximum exposure.",
    },
    {
      title: "What types of vehicles can I advertise on with Motionad?",
      description: "Motionad works with a variety of vehicles, including cabs, commercial buses, and other vehicles with advertising space.",
    },
    {
      title: "How much does it cost to advertise with Motionad?",
      description: "The cost of advertising with Motionad varies depending on factors such as the size of the ad, duration of the campaign, and the specific vehicles chosen for advertising. Contact us for a customized quote.",
    },
    {
      title: "Can I target specific areas for my advertising campaign?",
      description: "Yes, Motionad allows you to target specific areas for your advertising campaign, ensuring that your ads reach your desired audience.",
    },
    {
      title: "How long does it take to set up an advertising campaign with Motionad?",
      description: "Setting up an advertising campaign with Motionad can be done quickly and efficiently. Once you provide your ad content and target areas, we can launch your campaign within a few days.",
    },
    {
      title: "How can I track the performance of my advertising campaign with Motionad?",
      description: "Motionad provides real-time performance tracking, allowing you to monitor the effectiveness of your campaign and make adjustments as needed to optimize results.",
    },
    {
      title: "Can I change my advertising content or target areas during my campaign?",
      description: "Yes, you can make changes to your advertising content or target areas during your campaign. Contact us, and we will assist you with any adjustments you need.",
    },
    {
      title: "What kind of support does Motionad offer during my advertising campaign?",
      description: "Motionad offers dedicated support throughout your advertising campaign, ensuring that your ads are running smoothly and helping you achieve your advertising goals.",
    },
    {
      title: "What are the benefits of advertising with Motionad compared to other forms of advertising?",
      description: "Advertising with Motionad offers several benefits, including cost-effectiveness, targeted advertising, and the ability to reach a large audience.",
    },
    {
      title: "How can I get started with advertising on Motionad?",
      description: "Getting started with advertising on Motionad is easy. Simply contact us, and we will guide you through the process of setting up your advertising campaign.",
    },
  ]
  return(
    <div className="page-container">
      <NavBar
          openGetStartedModal = {() => getStartedModalRef?.current?.openModal()}
          closeGetStartedModal = {() => getStartedModalRef?.current?.closeModal()}
          showLinks={false}
        />     
      <GetStartedModal
        ref={getStartedModalRef}
      />  
      <section
        className="homepage-hero"
      >
        <div
          className="homepage-hero-contents"
        >
          <h1 className="homepage-hero-heading">
            Maximize Your <span className="homepage-hero-heading-highlight">Brand's Visibility</span> with Motionad
          </h1>
          <h3 className="homepage-hero-sub-heading">
            Connect with Cab and Commercial Vehicle Owners for Effective Advertising.
            Motionad offers a unique platform that connects your brand with cab and commercial vehicle owners, ensuring maximum exposure
          </h3>
          <div className="homepage-hero-ctas">
            <NavHashLink 
              exact to={'/sign-up'} 
              smooth
              className="homepage-hero-cta-primary"
            >
              Let's Promote Your Brand
            </NavHashLink>
            <NavHashLink 
              exact to={'/contact-us'} 
              smooth
              className="homepage-hero-cta-secondary"
            >
              Contact Sales
            </NavHashLink>
            {/* <a className="btn homepage-hero-cta-primary">
              <p>Let's Promote Your Brand</p>
            </a> */}
            {/* <a className="homepage-hero-cta-secondary">
              <p>Contact Sales</p>
            </a> */}
          </div>
        </div>
        <div
          className="homepage-hero-image"
        >
          <img 
            src={Car1}
            
            alt="sample of a branded cab"
          />
        </div>
      </section>
      <section
        className="homepage-clients-invite"
      >
        <div className="homepage-clients-invite-contents">


          <span className="landing-page-bullet-points">
            <img 
              src={CarIcon}
              className="car-icon-bullet"
            />
            <p>
            Targeted Advertising
            </p>
          </span>
          <p className="homepage-clients-invite-contents-description">
          Delivering ads to specific audiences based on demographics, interests, behavior, or location.            
          </p>

          <span className="landing-page-bullet-points">
            <img 
              src={CarIcon}
              className="car-icon-bullet"
            />
            <p>
            Cost-Effectiveness
            </p>
          </span>
          <p className="homepage-clients-invite-contents-description">
            Achieving optimal results with minimal resources or expenditure.
          </p>

          <span className="landing-page-bullet-points">
            <img 
              src={CarIcon}
              className="car-icon-bullet"
            />
            <p>
            Real-Time Performance Tracking
            </p>
          </span>
          <p className="homepage-clients-invite-contents-description">
          Monitoring and analyzing ad effectiveness as it happens, providing immediate insights for optimization.
          </p>
          <NavHashLink 
              exact to={'/sign-up'} 
              smooth
              className="homepage-clients-invite-contents-btn"
            >
              Let's Promote Your Brand
            </NavHashLink>
        </div>
        <div className="homepage-clients-invite-image">
          <img 
            src={Car2}
            alt="sample of a branded cab"
          />
        </div>
      </section>
      {/* <section className="homepage-pilot-section">
        <div className="homepage-pilot-section-image">
          <img
            src={PilotImage}
            alt="Drive in a vechicle"
          />
        </div>
        <div className="homepage-pilot-section-contents">
          <h1 className="homepage-pilot-section-contents-title">
            Got a car?
          </h1>
          <p className="homepage-pilot-section-contents-description">
          {`Are you a driver with any existing ride sharing platform? Are you interested in earning more on the same trips? Are you having issues  maintaining your vehicle?`}
          </p>
          <p className="homepage-pilot-section-contents-description">
          {`Join MotionAd today and earn up to ₦100,000 monthly, along with other cool benefits from our partners`}
          </p>
          <div className="homepage-pilot-section-contents-btn" onClick={() => getStartedModalRef?.current?.openModal()}>
            Become a Pilot
          </div>
        </div>
      </section> */}
      <section className="homepage-faq-section">
        <h1 className="homepage-faq-section-title">
          Frequently Asked Questions
        </h1>
        {
          faqs.map((item: any, index: number) => {
            return (
              <FAQItem 
                title={item.title}
                description={item.description}
                index={index}
                key={index}
                activeCurrentIndex={activeCurrentIndex}
                setActiveCurrentIndex={setActiveCurrentIndex}
              />
            )
          })
        }
      </section>
      <section className="cta-section">
        <h1 className="cta-section-title">
          Ready to be seen?
        </h1>
        <p className="cta-section-desc">
          Launch your first campaign today
        </p>
        <div className="cta-section-buttons">
          <div className="cta-section-primary-btn" onClick={() => getStartedModalRef?.current?.openModal()}>
            Get Started
          </div>
          <NavHashLink 
            className="cta-section-secondary-btn"
            exact to={'/contact-us'} 
            smooth
          >
            Contact Sales
          </NavHashLink>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default LandingPage001