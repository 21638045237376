import React, {
  useState,
} from 'react'

import "./VehicleCard.css"
import { VehicleCardPropsType } from './types'
import { BASE_URL } from '../../env'


function formatMoney(amount: number, decimalCount = 2, decimal = ".", thousands = ",") {
  
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";
    var amt = amount.toString()
    let i = parseInt(amt = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(parseInt(amt) - parseInt(i)).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e)
  }
}


export default function VehicleCard(props: VehicleCardPropsType) {
  return (
    <div 
      className={props.isSelected ? "vehicle-card-active-container" : "vehicle-card-container"}
      onClick={() => props.onSelect(props.index)}
      style={props.style}
    >
      <img
        src={BASE_URL + props.vehicleType.image}
        alt={props.vehicleType.title}
        className='vehicle-card-img'
      />
      <p className='vehicle-card-title'>
        {props.vehicleType.title}
      </p>
      <p className='vehicle-card-amt'>
      {props.vehicleType.currency} {formatMoney(props.vehicleType.total_cost_per_month)}/month
      </p>
    </div>
  )
}
