import { createSlice } from '@reduxjs/toolkit'

import { DEFAULT_STATE } from '../defaultState'
// import {
//   updateUserInfo,
// } from './utils'

export const userSlice = createSlice({
  name: 'user',
  initialState: DEFAULT_STATE.user,
  reducers: {  
    addMetaData: (state, action) => {
      state.metaData = {...state.metaData, ...action.payload}
    },
    reset: (state,) => {
      state = DEFAULT_STATE.user
    },
    logIn: (state, action) => {
      state.isAuthenticated = true
      state.businessProfile = action.payload.businessProfile
      state.userDetails = action.payload.userDetails
    },
    logOut: (state) => {
      state.isAuthenticated = false
      state.businessProfile = {
        business_name: "",
        contact_role: "",
        unique_id: "",
        country: "",
        profile_picture: "",
      }
      state.userDetails = {
        email: "",
        phone_number: "",
        first_name: "",
        last_name: "",
        is_email_verified: false,
      }
    },    
  },

  // extraReducers: (builder) => {
  //   builder      
  //     .addCase(updateUserInfo.fulfilled, (state, action) => {        
  //       state.userInfo = action.payload;
  //     })
  // },
})

export const { 
  addMetaData,
  reset,
  logIn,
  logOut
 } = userSlice.actions

// export {
//   updateUserInfo,
// }

export const userReducer = userSlice.reducer