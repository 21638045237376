import React, {
  useRef,
  useState,
} from "react";
// @ts-ignore
import { NavHashLink } from 'react-router-hash-link';

import '../Pages.css'
import './Partner.css'

import Car2 from '../../assets/svg/car-mockup3.svg'
import PilotImage from '../../assets/images/pilot-1.png'
import FAQItem from "../../components/FAQItem/FAQItem";
import { GetStartedModalType } from "../../components/GetStartedModal/types";
import NavBar from "../../components/NavBar/NavBar";
import GetStartedModal from "../../components/GetStartedModal";
import Footer from "../../components/Footer";

function Partner() {
  const [activeCurrentIndex, setActiveCurrentIndex] = useState(-1)
  const getStartedModalRef = useRef<GetStartedModalType>()

  const faqs = [
    {
      title: "How can I earn money with Motionad?",
      description: "By partnering with Motionad, you can earn money by allowing brands to place advertisements on your cab or commercial vehicle. This provides you with a new revenue stream without any additional effort on your part.",
    },
    {
      title: "Is my vehicle eligible for advertising with Motionad?",
      description: "Motionad works with a variety of vehicles, including cabs, commercial buses, and other vehicles with advertising space. As long as your vehicle meets the requirements, you can start earning money through advertising.",
    },
    {
      title: "How much can I earn from advertising with Motionad?",
      description: "The amount you can earn from advertising with Motionad depends on factors such as the size of the ad, the duration of the campaign, and the specific routes you travel. Contact us for more information on earning potential.",
    },
    {
      title: "Do I have control over the ads placed on my vehicle?",
      description: "Yes, you have control over the ads placed on your vehicle. Motionad works with you to ensure that the ads are relevant and suitable for your audience.",
    },
    {
      title: "Is there a minimum commitment for advertising with Motionad?",
      description: "Motionad offers flexible advertising options, allowing you to choose the duration and frequency of ads on your vehicle. There is no minimum commitment, so you can advertise as much or as little as you want.",
    },
    {
      title: "Can I track the performance of the ads on my vehicle?",
      description: "Yes, Motionad provides real-time performance tracking, allowing you to see how your ads are performing and how much you're earning. This transparency ensures that you're always informed about your advertising income.",
    },
    {
      title: "How do I get started with advertising on my vehicle?",
      description: "Getting started with advertising on your vehicle is easy. Simply contact us, and we will guide you through the process of signing up and getting your vehicle ready for advertising.",
    },
    {
      title: "Are there any costs involved in partnering with Motionad?",
      description: "There are no costs involved in partnering with Motionad. We handle all aspects of the advertising process, from ad placement to payment, so you can start earning money without any upfront investment.",
    },
    {
      title: "What support does Motionad offer to drivers?",
      description: "Motionad offers dedicated support to drivers, ensuring that they have all the information and assistance they need to maximize their earnings from advertising. Our team is always available to answer questions and provide guidance.",
    },
    {
      title: "Can I choose the brands that advertise on my vehicle?",
      description: "While you cannot choose specific brands to advertise on your vehicle, Motionad works with reputable brands to ensure that the ads are relevant and suitable for your audience. This helps maximize your earnings while maintaining the quality of the ads.",
    },
  ];
  
  return(
    <div className="page-container">
      <NavBar
          openGetStartedModal = {() => getStartedModalRef?.current?.openModal()}
          closeGetStartedModal = {() => getStartedModalRef?.current?.closeModal()}
        />     
      <GetStartedModal
        ref={getStartedModalRef}
      />  
      <section
        className="partner-hero"
      >
        <div
          className="partner-hero-contents"
        >
          <h1 className="partner-hero-heading">
            Become a <span className="partner-hero-heading-highlight">partner</span> with us and start <span className="partner-hero-heading-highlight">earning</span>
          </h1>
          <h3 className="partner-hero-sub-heading">
            As a driver with any ride sharing platform, we can help you earn more from your trips while providing loads of benefits.
          </h3>
          <div className="partner-hero-ctas">
            <NavHashLink
              exact to={'/sign-up?as=partner'} 
              smooth
              className="btn partner-hero-cta-primary"
            >
              <p>Become a Partner</p>
            </NavHashLink>
            <NavHashLink 
              className="btn partner-hero-cta-secondary"
              exact to={'/contact-us'} 
              smooth
            >
              <p>Contact Sales</p>
            </NavHashLink>
          </div>
        </div>
        <div
          className="partner-hero-image"
        >
          <img 
            src={PilotImage}
            alt="Driver in a car"
          />
        </div>
      </section>
      <section
        className="partner-clients-invite"
      >
        <div className="partner-clients-invite-contents">
          <h1 className="partner-clients-invite-contents-title">
            Drive as usual and earn much more
          </h1>
          <p className="partner-clients-invite-contents-description">
            Nothing changes with the way you currently operate when you become a MotionAd Partner. We provide you with the advert to place on your vehicle, then you can carry on with business awaiting your payments as well as other benefits.
          </p>
          <NavHashLink
            exact to={'/sign-up?as=partner'} 
            smooth 
            className="partner-clients-invite-contents-btn"
          >
            Get Started
          </NavHashLink>
        </div>
        <div className="partner-clients-invite-image">
          <img 
            src={Car2}
            alt="sample of a branded cab"
          />
        </div>
      </section>
      <section className="partner-pilot-section">
        <div className="partner-pilot-section-image">
          <img
            src={PilotImage}
            alt="Drive in a vechicle"
          />
        </div>
        <div className="partner-pilot-section-contents">
          <h1 className="partner-pilot-section-contents-title">
            Got a car?
          </h1>
          <p className="partner-pilot-section-contents-description">
          {`Are you a driver with any existing ride sharing platform? Are you interested in earning more on the same trips? Are you having issues  maintaining your vehicle?`}
          </p>
          <p className="partner-pilot-section-contents-description">
          {`Join MotionAd today and earn up to ₦100,000 monthly, along with other cool benefits from our partners`}
          </p>
          <NavHashLink
            exact to={'/sign-up?as=partner'} 
            smooth  
            className="partner-pilot-section-contents-btn">
            Become a Pilot
          </NavHashLink>
        </div>
      </section>
      <section className="partner-faq-section">
        <h1 className="partner-faq-section-title">
          Frequently Asked Questions
        </h1>
        {
          faqs.map((item: any, index: number) => {
            return (
              <FAQItem 
                title={item.title}
                description={item.description}
                index={index}
                key={index}
                activeCurrentIndex={activeCurrentIndex}
                setActiveCurrentIndex={setActiveCurrentIndex}
              />
            )
          })
        }
      </section>
      <section className="cta-section">
        <h1 className="cta-section-title">
          Ready to cash out?
        </h1>
        <p className="cta-section-desc">
          Create an account and receive your first campaign today
        </p>
        <div className="cta-section-buttons">
          <NavHashLink
            exact to={'/sign-up?as=partner'} 
            smooth 
            className="cta-section-primary-btn">
            Get Started
          </NavHashLink>
          <NavHashLink 
              exact to={'/contact-us'} 
              smooth 
              className="cta-section-secondary-btn">
            Contact Sales
          </NavHashLink>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Partner