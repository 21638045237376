import React, {
  useState,
} from 'react'


import './Dashboard.css'

import { DashboardPropsType } from './type'
import VerticalNavBar from '../../components/VerticalNavBar';
import { useNavigate } from 'react-router-dom';


export default function Dashboard (props: DashboardPropsType) {
  const [campaigns, setCampaigns] = useState([])
  const navigate = useNavigate()


  const createCampaign = () => {
    navigate("/create-campaign")
  }

  return (
    <div className={Boolean(campaigns.length) ? "dashboard-container" : 'dashboard-empty-container'}>
      <VerticalNavBar />
      <div className='dashboard-contents-section'>
        {
          !Boolean(campaigns.length) ? (
            <div
              className='dashboard-empty-campaign-section'
            >
              <h2>
                No Campaigns
              </h2>
              <p>
                You have no active or completed campaigns at the momment
              </p>
              <button
                onClick={createCampaign}
              >
                Create New Campaign
              </button>
            </div>
          ) : null
        }
      </div>
      {/* <button
        onClick={() => {
          dispatch(logOut())
        }}
      >
        Log Out
      </button> */}
    </div>
  )
}