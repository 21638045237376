import { useRef } from 'react'

import './Terms.css'
import '../Pages.css'
import { GetStartedModalType } from '../../components/GetStartedModal/types'
import NavBar from '../../components/NavBar/NavBar'
import GetStartedModal from '../../components/GetStartedModal'
import Footer from '../../components/Footer'


export default function Terms() {
  const getStartedModalRef = useRef<GetStartedModalType>()
  return (
    <div className="page-container">
      <NavBar
          openGetStartedModal = {() => getStartedModalRef?.current?.openModal()}
          closeGetStartedModal = {() => getStartedModalRef?.current?.closeModal()}
        />     
      <GetStartedModal
        ref={getStartedModalRef}
      />  
      <h1 className='terms-title'>
        Terms & Conditions
      </h1>
      <div className='terms-contents'>
        <p className='terms-contents-text'>
          Welcome to our Perfect Jubilee Motion Advertising LTD <span className='terms-contents-text-emphasize'>“The Platform”</span>. By accessing or using our Platform, you agree to be bound by these Terms and Conditions <span></span>“Terms”. Please read them carefully.
        </p>
        <p>
        <strong>"Advertiser"</strong> means any person or entity who advertises their brand on a vehicle through our Platform.
        </p>
        <p>
        <strong>"Vehicle Owner"</strong> means any person or entity who operates a vehicle on a ride sharing platform and is willing to display advertisements on their vehicle through our Platform.
        </p>
        <p>
          <strong>"Campaign"</strong> means the specific advertisement or group of advertisements created by an Advertiser and displayed on a Vehicle Owner's vehicle.
        </p>
        <p>
          <strong>"Platform"</strong> means the online platform operated by our company that facilitates the connection between Advertisers and Vehicle Owners.
        </p>

        <h3>2. Use of the Platform</h3>
        <p className='terms-contents-indented-text'>
          <strong>2.1.</strong> The Platform is provided to facilitate the placement of advertisements on vehicles by Advertisers through the use of Vehicle Owners who operate their vehicles on ride sharing platforms.
        </p>
        <p className='terms-contents-indented-text'>
          <strong>2.2.</strong> By registering as a User on the Platform, you confirm that you are at least 18 years old and that you have read, understood, and agreed to these Terms.
        </p>
        <p className='terms-contents-indented-text'>
          <strong>2.3.</strong> We reserve the right  to suspend or terminate any User's access to the Platform at any time without notice if they violate these Terms.
        </p>
        <p className='terms-contents-indented-text'>
          <strong>2.4.</strong>Users agree to provide accurate and complete information when registering on the Platform and to keep their information up-to-date.
        </p>
        <p className='terms-contents-indented-text'>
          <strong>2.5.</strong>Users agree not to use the Platform for any illegal or unauthorized purpose.
        </p>

        <h3>3. Advertisements</h3>
        <p className='terms-contents-indented-text'>
          <strong>3.1.</strong>Advertisers agree to comply with all applicable laws and regulations related to advertising, including but not limited to the Federal Trade Commission's guidelines on advertising and endorsement.
        </p>
        <p className='terms-contents-indented-text'>
          <strong>3.2.</strong>Advertisers are solely responsible for the content of their Campaigns and ensuring that they comply with these Terms.
        </p>

        
          <p className='terms-contents-indented-text'>
            <strong>3.3. Advertisers must provide</strong> all necessary materials for their Campaign, including graphics and text, to be displayed on Vehicle Owners' vehicles.
          </p>
          <p className='terms-contents-indented-text'>
            <strong>3.4. Advertisers agree</strong> that their Campaigns will not contain any content that is defamatory, obscene, discriminatory, or otherwise offensive.
          </p>
          <p className='terms-contents-indented-text'>
            <strong>3.5. Advertisers acknowledge</strong> that we reserve the right to reject or remove any Campaign that violates these Terms or for any other reason.
          </p>
        
        <h3>4. Vehicle Owners</h3>
        
          <p className='terms-contents-indented-text'>
            <strong>4.1. Vehicle Owners agree</strong> to comply with all applicable laws and regulations related to vehicle advertising, including but not limited to local ordinances and regulations.
          </p>
          <p className='terms-contents-indented-text'>
            <strong>4.2. Vehicle Owners are solely responsible</strong> for the content and placement of Advertiser Campaigns on their vehicles.
          </p>
          <p className='terms-contents-indented-text'>
            <strong>4.3. Vehicle Owners agree</strong> to display Advertiser Campaigns on their vehicles for the duration of the Campaign period specified by the Advertiser.
          </p>
          <p className='terms-contents-indented-text'>
            <strong>4.4. Vehicle Owners agree</strong> to promptly report any damage or issues with their vehicles to us or the Advertiser.
          </p>
          <p className='terms-contents-indented-text'>
            <strong>4.5. Vehicle Owners acknowledge</strong> that we reserve the right to reject or remove any vehicle from the Platform for any reason.
          </p>
        
        <h3>5. Payment</h3>
        
          <p className='terms-contents-indented-text'>
            <strong>5.1. Advertisers agree</strong> to pay the fees specified on the Platform for each Campaign they create.
          </p>
          <p className='terms-contents-indented-text'>
            <strong>5.2. Vehicle Owners will receive compensation</strong> for displaying Advertiser Campaigns on their vehicles as agreed upon with the Advertiser.
          </p>
          <p className='terms-contents-indented-text'>
            <strong>5.3. We will process payments</strong> to Vehicle Owners within 30 days after the end of the Campaign period.
          </p>
        
        <h3>6. Intellectual Property</h3>
        
          <p className='terms-contents-indented-text'>
            <strong>6.1. All intellectual property rights</strong> in the Platform, including but not limited to trademarks, copyrights, and patents, are owned by us or our licensors.
          </p>
          <p className='terms-contents-indented-text'>
            <strong>6.2. Users may not use</strong> any intellectual property belonging to us or our licensors without our prior written consent.
          </p>
        
        <h3>7. Limitation of Liability</h3>
        
          <p className='terms-contents-indented-text'>
            <strong>7.1. We are not responsible</strong> for any damages resulting from the use or inability to use the Platform or from any content displayed on the Platform.
          </p>
          <p className='terms-contents-indented-text'>
            <strong>7.2. We are not liable</strong> for any loss of profits, revenue, or data arising from the use of the Platform.
          </p>
          <p className='terms-contents-indented-text'>
            <strong>7.3. We do not guarantee</strong> the availability or accuracy of any content displayed on the Platform.
          </p>
          <p className='terms-contents-indented-text'>
            <strong>7.4. Users agree</strong> to indemnify and hold us harmless from any claims, damages, or losses arising from their use of the Platform or their violation of these Terms.
          </p>
        
        <h3>8. Privacy Policy</h3>
        
          <p className='terms-contents-indented-text'>
            <strong>8.1. We collect and use personal information</strong> from Users in accordance with our Privacy Policy, which is incorporated by reference into these Terms.
          </p>
          <p className='terms-contents-indented-text'>
            <strong>8.2. By using the Platform,</strong> Users agree to the collection and use of their personal information as described in the Privacy Policy.
          </p>
        
        <h3>9. Modification of Terms</h3>
        
          <p className='terms-contents-indented-text'>
            <strong>9.1. We reserve the right</strong> to modify these Terms at any time without notice.
          </p>
          <p className='terms-contents-indented-text'>
            <strong>9.2. Any modifications</strong> to these Terms will be effective immediately upon posting on the Platform.
          </p>
          <p className='terms-contents-indented-text'>
            <strong>9.3. Users are responsible</strong> for regularly reviewing these Terms for any changes.
          </p>
        
        <h3>10. Governing Law</h3>
        
          <p className='terms-contents-indented-text'>
            <strong>10.1. These Terms are governed by</strong> and construed in accordance with the laws of the jurisdiction where our company is located.
          </p>
          <p className='terms-contents-indented-text'>
            <strong>10.2. Any disputes arising</strong> from or related to these Terms or the use of the Platform will be subject to the exclusive jurisdiction of the courts in that jurisdiction.
          </p>
        
        <h3>11. Entire Agreement</h3>
        
          <p className='terms-contents-indented-text'>
            <strong>11.1. These Terms, together with the Privacy Policy,</strong> constitute the entire agreement between Users and our company regarding the use of the Platform.
          </p>
          <p className='terms-contents-indented-text'>
            <strong>11.2. If any provision</strong> of these Terms is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.
          </p>
        
        
        <p style={{marginTop: 20, marginBottom: 40,}}>If you have any questions or concerns about these Terms, please contact us at <strong><a href="mailto:hello@motionad.ng">hello@motionad.ng</a>.</strong></p>
      </div>
      <Footer />
    </div>
  )
}