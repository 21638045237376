import React from "react";
// @ts-ignore
import { NavHashLink } from 'react-router-hash-link';
import { useDispatch, useSelector } from "react-redux";

import './VerticalNavBar.css'

import Logo from '../../assets/svg/Logo.svg'
import Logout from '../../assets/svg/log-out.svg'
import { DefaultState } from "../../redux/types";
import { BASE_URL } from "../../env";
import { logOut } from "../../redux/user";

export default function VerticalNavBar (props: any) {
  const user = useSelector((state: DefaultState) => state.user);
  const dispatch = useDispatch()

  return (
    <div
      className="v-nav-container"
    >
      <NavHashLink
        exact to="/dashboard" 
        smooth                     
      >
        <img 
          src={Logo}
          alt={`MotionAd Logo`}
          className="v-nav-logo"
        />
      </NavHashLink>

      <div className="v-nav-user-data">
        <div className="v-nav-avatar">
          {
            Boolean(user.businessProfile.profile_picture?.length) ? (
              <img 
                src={BASE_URL + user?.businessProfile?.profile_picture}
                alt={user?.businessProfile?.business_name}
                className="v-nav-avatar-img"
              />
            ) : (
              <p>
                {user?.businessProfile?.business_name[0]}
              </p>
            )
          }
          
        </div>
        <p
          className="v-nav-user-data-business-name"
        >
          {user.businessProfile.business_name}
        </p>
        <p
          className="v-nav-user-data-user-email"
        >
          {user.userDetails.email}
        </p>
        <img 
          src={Logout}
          alt="Log out button"
          className="v-nav-logout"
          onClick={() => dispatch(logOut())}
        />
      </div>
    </div>
  )
}