import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
// @ts-ignore
import Modal from 'react-modal';
import { 
  useNavigate,
} from "react-router-dom";

import './GetStartedModal.css'

import CarAndBG from '../../assets/images/CarAndBG.png'
import CloseIcon from '../../assets/svg/CloseIcon.svg'
import SignIn from '../../assets/svg/sign-in.svg'

const GetStartedModal = forwardRef((props, ref) => {
  const [modalIsOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate()

  useImperativeHandle(ref, () => ({
    openModal: openModal,
    closeModal: closeModal,
  }))

  

  const closeModal = () => {
    console.log('closeModal')
    setIsModalOpen(false)
  }

  const openModal = () => {
    console.log('openModal')
    setIsModalOpen(true)
  }

  function driverSignUp() {
    var url = 'https://forms.gle/LGRTSjC62DumRVMo8'; // Replace with your desired URL
    window.open(url, '_blank');
  }

  function clientSignUp() {
    var url = 'https://forms.gle/kR1CFZ64gLN85kFy8'; // Replace with your desired URL
    window.open(url, '_blank');
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      zIndex: 99,
      backgroundColor: 'white',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      backdropFilter: 'blur(20px)',
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Vessel Modal"
      // overlayClassName="modal-overlay"
      className="get-started-modal" 

    >
      <div className='get-started-modal-section'>
        <div className='get-started-modal-contents'>
          <div className='get-started-modal-close-btn-section'>
            <div className='get-started-modal-close-btn' onClick={closeModal}>
              <img 
                src={CloseIcon}
                alt="Close Buttons"
              />
            </div>
          </div>
          <div className='get-started-modal-cta-section'>
            <div 
              className='get-started-modal-cta-btn' 
              // onClick={() => navigate('/sign-up?as=client', { replace: false })}
              onClick={clientSignUp}
            >
              <p>Sign up to promote your brand</p>
              <img 
                src={SignIn}
                alt="Sign up as a brand"
              />
            </div>
            <div 
              className='get-started-modal-cta-btn' 
              onClick={driverSignUp}
              // onClick={() => navigate('/sign-up?as=partner', { replace: false })}
            >
              <p>Sign up to earn as a driver </p>
              <img 
                src={SignIn}
                alt="Sign up as a brand"
              />
            </div>
          </div>
        </div>
        <img 
          src={CarAndBG}
          alt="Car on blue background"
          className='bg-img'
        />
      </div>
    </Modal>
  )
})

export default GetStartedModal