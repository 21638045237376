import { BASE_URL } from "../env"
import { apiFetch, authApiFetch } from "./utils"


export const send_contact_form = async (params: any) => {
  try {
    const url = BASE_URL + '/api/notifications/send_contact_form'
    return await apiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}

export const subscribe_to_newsletters = async (params: any) => {
  try {
    const url = BASE_URL + '/api/notifications/subscribe_to_newsletters'
    return await apiFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params)
  })
  } catch (error) {
    throw error
  }
}

