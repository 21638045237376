import { DefaultState } from "./types";

export const DEFAULT_STATE: DefaultState = {
  user: {
    isAuthenticated: false,
    userDetails: {
      email: "",
      phone_number: "",
      first_name: "",
      last_name: "",
      is_email_verified: false,
    },
    businessProfile: {
      business_name: "",
      contact_role: "",
      unique_id: "",
      country: "",
      profile_picture: "",
    },
    metaData: {},
  }
}