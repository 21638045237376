import { BASE_URL } from "../env"
import { apiFetch, authApiFetch } from "./utils"

export const get_region_options = async (regionCode: string) => {  
  try {
    var url = BASE_URL + `/api/campaigns/get_region_options?region_code=${regionCode}`
    return await authApiFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
  } catch (error) {
    throw error
  }
}

export const get_camp_options = async () => {
  try {
    var url = BASE_URL + '/api/account/get_camp_options'
    return await authApiFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
  } catch (error) {
    throw error
  }
}

export const get_regions = async (countryCode: string) => {
  try {
    var url = BASE_URL + `/api/campaigns/get_regions?country_code=${countryCode}`
    return await authApiFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
  } catch (error) {
    throw error
  }
}

