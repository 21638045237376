import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist'
import storage from "redux-persist/lib/storage";

import reducer from './reducer'
import { DefaultState } from './types';


const persistConfig = {
  key: 'root',
  storage,
  version: 1,
  migrations: {
    1: (state: DefaultState) => {
      // Migration logic from version 1 to 2
      // return {
      //   ...state,
      //   newField: defaultValue, // Set a default value for the new field
      // };

      // Removing a field
      // const { fieldToRemove, ...updatedState } = state;
      // return updatedState;
    },
    2: (state: DefaultState) => {
      // Migration logic from version 2 to 3
      // return {
      //   ...state,
      //   nestedObject: {
      //     // Update the structure of the nested object
      //     ...state.nestedObject,
      //     newField: defaultValue,
      //   },
      // };
    },
  },
}

const persistedReducer = persistReducer(persistConfig, reducer)

export const store = configureStore({
  reducer: persistedReducer,
})

export const persistor = persistStore(store)